import React from "react";
import styled from "styled-components";
import { Text } from "../../components/bodycomponents";
import ItemTratamiento from "./ItemTratamiento";

const TratamientosFacialesSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: left;
  padding: 2% 15%;

`;

const Title = styled.h2`
  color: var(--verde_oscuro, #26444d);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06563rem;
  margin-bottom: 0;
`;

const TratamientosFaciales = () => {
  return (
    <TratamientosFacialesSection>
      <Title>Tratamientos faciales</Title>
      <Text style={{ textAlign: "justify" }}>
        Procedimientos adecuados para cada tipo de paciente; desde valoración,
        extracciones, microdermoabrasión, antiage (E-light y radiofrecuencia),
        antiacné, antimanchas, dermapunt y sugerencias de tratamiento en casa.
      </Text>
      <ItemTratamiento />
    </TratamientosFacialesSection>
  );
};

export default TratamientosFaciales;
