import React from "react";
import styled from "styled-components";

export const TitleBody = styled.h2`
  color: #26444d;
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06563rem;
  margin: 0;
`;
