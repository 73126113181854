import React from "react";
import styled from "styled-components";
import ScuareBlock from "./ScuareBlock";
import RectangleBlock from "./RectangleBlock";

import icon1 from "../../Resources/Servicios/Frame.svg";
import icon2 from "../../Resources/Servicios/Frame (1).svg";
import icon3 from "../../Resources/Servicios/Frame (2).svg";
import icon4 from "../../Resources/Servicios/Frame (3).svg";
import icon5 from "../../Resources/Servicios/Frame (4).svg";
import icon6 from "../../Resources/Servicios/Frame (5).svg";
import icon7 from "../../Resources/Servicios/Frame (6).svg";

import img1 from "../../Resources/Servicios/0746927f6fe3a37757fafc06f0da1a13.jpg";
import img2 from "../../Resources/Servicios/9b261f7cd431ae1a208a073c270a209f.jpg";

const ScuareBlocksData = [
  {
    id: 1,
    icon: icon1,
    title: "PLAN ALIMENTICIO",
    description:
      "Creamos planes alimenticios dependiendo de tus necesidades nutrimentales, rutina laboral y social para una mejor adaptación al nuevo sistema, logrando mantenerlo.",
  },
  {
    id: 2,
    icon: icon4,
    title: "EJERCICIOS LOCALIZADOS",
    description:
      "Damos rutinas de ejercicio personalizadas, que ayuden a fortalecer el tono muscular y mejoren la textura de la piel.",
  },
  {
    id: 2,
    icon: icon7,
    title: "TIPS Y CONSEJOS",
    description:
      "Brindamos tips fáciles de realizar que ayuden a mantener el estilo de vida adquirido, llevando a consolidar los nuevos hábitos. También damos consejos de cocina, recetas y más.",
  },
];

const RectangleBlocksData = [
  {
    id: 1,
    icon: icon2,
    title: "ESTUDIOS",
    description:
      "Realizamos Resonancia Cuántica para conocer el estado de salud general e Impedancia Bioeléctrica para ver la composición del cuerpo. Así podemos encontrar alteraciones del perfil metabólico.",
  },
  {
    id: 2,
    icon: icon3,
    title: "MESOTERAPIA",
    description:
      "Por medio de medicina homeopática inyectada localizamos la reducción de medidas, concentrando la pérdida de grasa en una zona específica. Mejora la piel o flacidez, elimina la celulitis.",
  },
  {
    id: 3,
    icon: icon5,
    title: "DEPILACIÓN",
    description:
      "Con tecnología E-light, que combina tecnología IPL más Radiofrecuencia, penetra el folículo piloso y lo destruye con lo cual el vello no vuelve a crecer. Ayuda a mejorar la textura de la piel gracias a la radiofrecuencia.",
  },
  {
    id: 4,
    icon: icon6,
    title: "MASAJES",
    description:
      "Estar en equilibrio también influye en nuestro cuerpo y malestar físico, por ello ofrecemos masajes relajantes, deportivos, depurativos y descontracturantes.",
  },
];

const Wrapper = styled.div`
  margin-top: -35vh;
  margin-bottom: 5%;
  z-index: 1;
  @media screen and (max-width: 768px) {
    margin-top: -20vh; /* Ajustar el margen superior en dispositivos móviles */
  }
`;

const Image = styled.img`
  width: 21%;
  height: 26vw;
  max-height: 22rem;
  min-height: 25.75rem;
  object-fit: cover;
  border-radius: 1.875rem;
  background: lightgray -38.746px -159px / 117.16% 157.767% no-repeat;
  box-shadow: 0px 2.18954px 10.94771px 0px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 8% auto;
    height: 30vh;
    max-height: none;
    border-radius: 0;
    border-radius: 1.875rem;
    background: lightgray -270px -12.333px / 184.324% 110.356% no-repeat;
    box-shadow: 0px 2.18954px 10.94771px 0px rgba(0, 0, 0, 0.15);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-top: 2%;
  @media screen and (max-width: 768px) {
    flex-direction: column; /* Cambiar la dirección en dispositivos móviles */
  }
`;

const ServiceBody = () => {
  return (
    <Wrapper>
      <Row>
        <ScuareBlock ScuareBlocksData={ScuareBlocksData[0]} />
        <RectangleBlock RectangleBlocksData={RectangleBlocksData[0]} />
        <RectangleBlock RectangleBlocksData={RectangleBlocksData[1]} />
      </Row>
      <Row>
        <Image
          src={img1}
          alt={"imagen 1"}
          style={{ translate: "0 -1rem", marginRight: "1%" }}
        />
        <ScuareBlock
          ScuareBlocksData={ScuareBlocksData[1]}
          style={{ height: "fit-content" }}
        />
        <Image
          src={img2}
          alt={"imagen 2"}
          style={{ translate: "0 2rem", marginLeft: "1%" }}
        />
      </Row>
      <Row>
        <RectangleBlock RectangleBlocksData={RectangleBlocksData[2]} />
        <RectangleBlock RectangleBlocksData={RectangleBlocksData[3]} />
        <ScuareBlock
          ScuareBlocksData={ScuareBlocksData[2]}
          style={{
            paddingBottom: "1.5%",
            alignSelf: "end",
          }}
        />
      </Row>
    </Wrapper>
  );
};

export default ServiceBody;
