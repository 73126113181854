import React from "react";
import styled from "styled-components";

const TestimonioContainer = styled.div`
  @media (max-width: 768px) {
    width: 80%;
    margin: 0 10%;
  }
  * {
    z-index:1;
  }
`;

const FotoNombreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative; /* Agregamos posición relativa al contenedor */
`;

const TestimonioFoto = styled.img`
  width: 2.75rem;
  margin-right: 20px;
  height: 3rem;
  border-radius: 25%;
`;

const TestimonioNombre = styled.h3`
  color: var(--verde_oscuro, #26444d);
  /* Poppins/subtitle-21pt-semibold */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.03938rem;
`;

const TestimonioParrafo = styled.p`
  color: var(--verde_oscuro, #26444d);
  /* Poppins/text-15pt-regular */
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02813rem;
`;

function TestimonioBlock({ testimonio }) {
  return (
    <TestimonioContainer>
      <FotoNombreContainer>
        <TestimonioFoto src={testimonio.foto} alt={testimonio.nombre} />
        <TestimonioNombre>{testimonio.nombre}</TestimonioNombre>
      </FotoNombreContainer>
      <TestimonioParrafo>{testimonio.parrafo}</TestimonioParrafo>
    </TestimonioContainer>
  );
}

export default TestimonioBlock;
