import React, { useRef } from "react";
import styled from "styled-components";
import { Text, TextPacifico } from "../../components/bodycomponents";
import NavigateBar from "../../components/NavigateBar";
import Footer from "../../components/Footer";
import LastFooter from "../../components/LastFooter";
import TratamientosCorporales from "./TratamientosCorporales";
import TratamientosFaciales from "./TratamientosFaciales";
import spaLogo from "../../Resources/spa.svg";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  max-width: 1820px;
  margin: 0 auto;
`;

const HeadBg = styled.div`
  padding-top: 3%;
  background-color: var(--verde_oscuro, #26444d);
  height: 30hv;
  background-position: center;
  max-height: 400px;
  position: relative;
`;

const EntitledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 2%;
`;
const BackColor = styled.div`
  background: var(--verde_claro, #e7f2ee);
  position: absolute;
  width: 100%;
  height: 38rem;
  z-index: -1;
  max-width: 1920px;
  margin: 0 auto;
`;

const SpaDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-end;
`;

const SpaLogo = styled.img`
  position: absolute;
  right: 60rem;
  top: 1240px;
  width: 25rem;
  z-index: -1;
  opacity: 0.1;
`;

const Tratamientos = () => {
  const navigateto = useNavigate();
  const contacto = useRef();

  const handleClick = () => {
    navigateto("/#testimonials");
  };

  const handleClickContacto = () => {
    contacto.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Wrapper>
      <HeadBg>
        <NavigateBar
          handleClick={handleClick}
          handleClickContacto={handleClickContacto}
        />
        <EntitledContainer>
          <Text
            style={{
              color: "#fff",
              marginBottom: "-5px",
              fontSize: "1.5rem",
              fontWeight: "700",
            }}
          >
            Encuentra el tratamiento
          </Text>
          <TextPacifico style={{ marginTop: "0" }}>
            perfecto para ti
          </TextPacifico>
        </EntitledContainer>
      </HeadBg>
      <TratamientosCorporales />
      <BackColor />
      <SpaDiv>
        <SpaLogo src={spaLogo} alt="logo spa" />
      </SpaDiv>
      <TratamientosFaciales />
      <Footer contacto={contacto} />
      <LastFooter />
    </Wrapper>
  );
};

export default Tratamientos;
