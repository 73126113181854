import React, { useState } from "react";
import styled from "styled-components";
import Icono from "../Resources/phone_in_talk_FILL1_wght300_GRAD0_opsz24.svg";
import logo from "../Resources/e659c464421160356cb87c63324f613a.png";
import { useNavigate } from "react-router-dom";

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  * {
    z-index: 1;
  }
`;

const Nav = styled.nav`
  background-color: white;
  display: flex;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  max-width: fit-content;
  border-radius: 10px;
  border-color: black;
  border-width: 1px;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 1.25rem;
  background: #fff;

  /* shadow-cajas */
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
`;

const LogoContainer = styled.div`
  @media (max-width: 768px) {
    margin-right: 50vw;
    margin-left: -5vw;
  }
`;

const LogoImage = styled.img`
  width: 50px;
`;

const NavLinkContainer = styled.div`
  display: flex;
  align-items: center;
  color: #152500;
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.03938rem;
  margin-left: 5rem;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    padding: 10px;
    text-align: left;
    display: ${({ showMenu }) => (showMenu ? "flex" : "none")};
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: #26444d;
  margin-left: 1rem;

  &:hover {
    color: #fb7e62; /* Cambiamos el color del texto a verde en hover */
  }

  @media (max-width: 1200px) {
    margin: 10px 0;
    align-items: flex-start;
  }
`;

const NavLink2 = styled.a`
  text-decoration: none;
  color: #26444d;
`;

const Icon = styled.img`
  width: 35%;
  margin-right: 5px;
`;

const DBlock = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: right;
  margin-left: 90px;
  @media (max-width: 1200px) {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    margin-left: 0;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  @media (max-width: 1200px) {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 20px;
    position: relative;
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: black;
      border-radius: 9px;
      opacity: 1;
      left: 20px;
      transform: rotate(0deg);
      transition: all 0.25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0px;
    }
    span:nth-child(2) {
      top: 10px;
    }
    span:nth-child(3) {
      top: 20px;
    }
    ${({ showMenu }) => showMenu && `
    span:nth-child(1) {
      transform: translateY(10px) rotate(45deg); /* Combined transform for better animation */
    }
    span:nth-child(2) {
      opacity: 0; /* Hide the middle line */
    }
    span:nth-child(3) {
      transform: translateY(-10px) rotate(-45deg); /* Combined transform */
    }
  `}
`;


const LogoAndIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ContactDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: start;
  color: var(--verde_oscuro, #26444d);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02813rem;
  text-transform: uppercase;
`;
const Phone = styled.p`
  margin: 0;
  color: var(--verde_oscuro, #26444d);
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.03188rem;
`;

const NavigateBar = ({ style, handleClick, handleClickContacto }) => {
  const [showMenu, setShowMenu] = useState(false);
  const navigateTo = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <CenteredContainer style={style}>
      <Nav onClick={toggleMenu}>

        <LogoAndIconContainer onClick={toggleMenu}>
          <LogoContainer>
            <LogoImage src={logo} alt="Logo" />
          </LogoContainer>

          <HamburgerIcon showMenu={showMenu} onClick={toggleMenu}>
            <span className={showMenu ? 'active' : ''}></span>
            <span className={showMenu ? 'active' : ''}></span>
            <span className={showMenu ? 'active' : ''}></span>
          </HamburgerIcon>
        </LogoAndIconContainer>
        <NavLinkContainer showMenu={showMenu}>
          <NavLink href={"/"}>Inicio</NavLink>
          <NavLink href={"/nosotros"}>Nosotros</NavLink>
          <NavLink href={"/servicios"}>Servicios</NavLink>
          <NavLink href={"/tratamientos"}>Tratamientos</NavLink>
          <NavLink href={"#testimonials"} onClick={handleClick}>
            Testimonios
          </NavLink>
          <NavLink href={"#contacto"} onClick={handleClickContacto}>
            Contacto
          </NavLink>
          <NavLink href={"/faq"}>FAQ</NavLink>
          <DBlock>
            <Icon src={Icono} alt="Cell Icon" />
            <ContactDiv>
              <NavLink2 href="tel:8444165301" target="_blank" rel="telefono">
                Llámanos
              </NavLink2>
              <Phone> 844 416 53 01</Phone>
            </ContactDiv>
          </DBlock>
        </NavLinkContainer>
      </Nav>
    </CenteredContainer>
  );
};

export default NavigateBar;
