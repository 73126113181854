import React, { useRef } from "react";
import { Wrapper } from "../../components/bodycomponents";
import NavigateBar from "../../components/NavigateBar";
import styled from "styled-components";
import background from "../../Resources/eceba32573766b2a1e5d35c8dae1137b.jpg";
import Footer from "../../components/Footer";
import LastFooter from "../../components/LastFooter";
import AboutNosotros from "./AboutNosotros";
import anios from "../../Resources/Group 4086.svg";
import { useNavigate } from "react-router-dom";

const HeadBg = styled.div`
  padding-top: 3%;
  background-image: url(${background});
  background-color: #dddddd;
  background-size: cover;
  background-position: center;
  max-height: 400px;
  position: relative;
`;

const Hero = styled.div`
  background-size: cover;
  background-position: center;
  height: 80vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  max-height: 800px;
`;

const ParrafoContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

const Titulo = styled.h1`
  color: #26444d;
  font-size: 2.1875rem;
  margin-top: 4%;
  margin-bottom: 0;
  font-weight: 700;
`;

const Parrafo = styled.p`
  color: #26444d;
  width: 50%;
  max-width: 800px;
  font-weight: 500;
  font-size: 1.1rem;
  @media (max-width: 768px) {
    width: 85%;
  }
`;

const PreFoot = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  background: var(--verde_claro, #e7f2ee);
`;

const Nota = styled.p`
  color: var(--verde_oscuro, #26444d);
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5625rem;
  letter-spacing: 0.05813rem;
`;

const YearContainer = styled.div`
  position: absolute;
  top: 16.5rem;
  left: 140vh;
  @media (max-width: 768px) {
    position: relative;
    top: -150px;
    left: 20%;
    z-index: 0;
    margin-bottom: -30%;
  }
`;

const Year = styled.img`
  width: 15rem;
`;

const Nosotros = () => {
  const navigateto = useNavigate();
  const contacto = useRef();

  const handleClickContacto = () => {
    contacto.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClick = () => {
    navigateto("/#testimonials");
  };

  return (
    <Wrapper>
      <HeadBg>
        <NavigateBar
          handleClick={handleClick}
          handleClickContacto={handleClickContacto}
        />
        <Hero />
      </HeadBg>
      <YearContainer>
        <Year src={anios} />
      </YearContainer>

      <Titulo style={{ textAlign: "center" }}>
        Somos una clínica integral
      </Titulo>
      <ParrafoContainer>
        <Parrafo>
          En Piltic Medic Spa combinamos la Bariatría, Nutrición y Medicina
          Antienvejecimiento para lograr resultados completos y satisfactorios
          en nuestros pacientes, garantizado con 32 años de experiencia
        </Parrafo>
      </ParrafoContainer>
      <AboutNosotros />
      <PreFoot>
        <Nota>
          No esperes más, con nosotros verás cambios desde <strong>HOY</strong>
        </Nota>
      </PreFoot>

      <Footer contacto={contacto} />
      <LastFooter />
    </Wrapper>
  );
};

export default Nosotros;
