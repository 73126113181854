import React, { useRef } from "react";
import styled from "styled-components";
import { Text, TextPacifico, Wrapper } from "../../components/bodycomponents";
import NavigateBar from "../../components/NavigateBar";
import Footer from "../../components/Footer";
import LastFooter from "../../components/LastFooter";
import Preguntas from "./Preguntas";
import bg from "../../Resources/faq/d86a20c5552ca49d78d35ebdbcba174a.jpg";
import { useNavigate } from "react-router-dom";

const HeadBg = styled.div`
  padding-top: 3%;
  background-image: url(${bg});
  height: 30hv;
  background-position: top;
  background-size: cover;
  max-height: 400px;
  position: relative;
`;

const EntitledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 2%;
`;
const BackColor = styled.div`
  background: var(--verde_claro, #e7f2ee);
  position: absolute;
  width: 100%;
  height: 38rem;
  z-index: -1;
`;
const Faq = () => {
  const contacto = useRef();
  const navigateto = useNavigate();
  const handleClick = () => {
    navigateto("/#testimonials");
  };

  const handleClickContacto = () => {
    contacto.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Wrapper>
      <HeadBg>
        <NavigateBar
          handleClick={handleClick}
          handleClickContacto={handleClickContacto}
        />
        <EntitledContainer>
          <Text
            style={{
              marginBottom: "-5px",
              fontSize: "1.5rem",
              fontWeight: "700",
              color: "#D7DD7E",
              textAlign: "center",
            }}
          >
            ¡Estamos para ti!
          </Text>
          <TextPacifico
            style={{ marginTop: "0", color: "#fff", textAlign: "center" }}
          >
            Respondemos tus dudas
          </TextPacifico>
        </EntitledContainer>
      </HeadBg>
      <Preguntas />
      <Footer contacto={contacto} />
      <LastFooter />
    </Wrapper>
  );
};

export default Faq;
