import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Switch, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/home";
import Nosotros from "./pages/Nosotros/Nosotros";
import Tratamientos from "./pages/Tratamientos/Tratamientos";
import Faq from "./pages/Faq/Faq";
import Servicios from "./pages/Servicios/Servicios";
import AvisoPrivacidad from "./pages/AvisoPrivacidad/Aviso";

function Cpanel() {
  // 👇️ redirect to external URL
  //window.location.replace("https://piltic.com,mx/cpanel");
  window.location.replace("https://piltic.com.mx/cpanel/");
  return null;
}
function Aviso() {
  // 👇️ redirect to external URL
  //window.location.replace("https://piltic.com,mx/cpanel");
  window.location.replace("https://piltic.com.mx/Aviso_de_privacidad/");
  return null;
}

function App() {
  return (
    <Switch>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<Nosotros />} />\
        <Route path="/Servicios" element={<Servicios />} />\
        <Route path="/tratamientos" element={<Tratamientos />} />
        <Route path="/cpanel" element={<Cpanel />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/Aviso_de_privacidad" element={<Aviso />} />
      </Routes>
    </Switch>
  );
}

export default App;
