import React from "react";
import styled from "styled-components";
import SideImage from "../../Resources/65217b91a89ea1735da59f3ac8cba0cb.jpg";
import {
  HeroButton,
  TextPacifico,
  TitleH2,
  TitlePacifico,
} from "../../components/bodycomponents";

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  @media (max-width: 768px) {
    margin: 5%;
    * {
      text-align: center;
    }
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: fit-content;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
  }
`;

const LeftColumn = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  @media (min-width: 768px) {
    max-width: 480px;
    text-align: center;
  }
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: 1.875rem 0rem 1.875rem 1.875rem;
  background: lightgray 50% / cover no-repeat;

  /* drop_shadow-img */
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: auto;
  object-fit: cover;

  @media (min-width: 768px) {
    width: 450px;
    height: 550px;
  }
`;

const RightColumn = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 0;

  @media (min-width: 768px) {
    max-width: 450px;
    flex: 0 0 50%;
    text-align: center;
  }
`;

const Title = styled.h2`
  color: #6cad9a;
  font-style: normal;
  font-size: 1.3125rem;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06563rem;
  margin: 0;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Subtitle = styled.h3`
  color: #26444d;
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06563rem;
  margin: 0;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Paragraph = styled.p`
  color: #26444d;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.03563rem;
  margin-bottom: 5%;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Sect = styled.div`
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const AboutUs = (props) => {
  return (
    <CenteredContainer>
      <Section>
        <LeftColumn>
          <Image src={SideImage} alt="Imagen" />
        </LeftColumn>
        <RightColumn>
          <Title>SOMOS</Title>
          <Subtitle>Piltic Medic Spa</Subtitle>
          <Paragraph>
            Ofrecemos un manejo médico integral del peso con planes de
            alimentación personalizados de acuerdo a las necesidades de cada
            paciente. Con raciones libres aprendiendo a nutrirse.
          </Paragraph>
          <Paragraph>
            Lograrás la MODIFICACIÓN DE HÁBITOS ALIMENTICIOS CON ÉXITO, para no
            recuperar el peso perdido.
          </Paragraph>
          <TitlePacifico style={{ marginBottom: "0" }}>
            Es cuestión de actitud
          </TitlePacifico>
          <TextPacifico style={{ marginTop: "0" }}>¡Sólo por hoy!</TextPacifico>
          <Sect style={{ marginTop: "60px" }}>
            <HeroButton href={"#contacto"} onClick={props.handleClickContacto}>
              Contáctanos
            </HeroButton>
          </Sect>
        </RightColumn>
      </Section>
    </CenteredContainer>
  );
};

export default AboutUs;
