import React, { useRef } from "react";
import styled from "styled-components";
import { Text, TextPacifico, Wrapper } from "../../components/bodycomponents";
import NavigateBar from "../../components/NavigateBar";
import { useNavigate } from "react-router-dom";

const HeadBg = styled.div`
  padding-top: 30px;
  background: var(--verde_claro, #e7f2ee);
  height: 80vh;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: -1;
  @media (max-width: 768px) {
    height: 80vh;
  }
`;

const EntitledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 8%;
  z-index: 0;
  @media (max-width: 768px) {
    margin-top: 30%;
  }
`;
const AvisoContainer = styled.div`
  max-width: 800px; /* Limita el ancho para mejor legibilidad */
  margin-bottom: 100px;
  margin: 0 auto;  /* Centra el contenedor horizontalmente */
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Añade una sombra sutil */
`;

const Parrafo = styled.p`
  line-height: 1.6; /* Mejora la legibilidad del texto */
  margin-bottom: 15px;
`;
const ParrafoBold = styled(Parrafo)`
  font-weight: bold;
`;


const AvisoPrivacidad = () => {
  const navigateto = useNavigate();
  const contacto = useRef();

  const handleClick = () => {
    navigateto("/#testimonials");
  };

  const handleClickContacto = () => {
    contacto.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Wrapper>
      <NavigateBar
        style={{
          marginBottom: "-7%",
          position: "absolute",
          top: "40px",
          left: "50%",
          translate: "-50%",
        }}
        handleClick={handleClick}
        handleClickContacto={handleClickContacto}
      />
      <HeadBg>
        <EntitledContainer>
          <Text
            style={{
              marginBottom: "-5px",
              fontSize: "2.1875rem",
              fontWeight: "700",
              color: "#6CAD9A",
              textAlign: "center",
            }}
          >
            Aviso de
          </Text>
          <TextPacifico
            style={{
              marginTop: "10px",
              color: "#26444D",
              fontSize: "4.0625rem",
            }}
          >
            Privacidad
          </TextPacifico>
   
      <AvisoContainer>
        <Parrafo>
          El Presente Aviso de Privacidad se suscribe en la entidad de Saltillo
          Coahuila, por PILTIC MEDIC SPA, con domicilio en Monclova 1621, Colonia
          Republica Poniente, C.P 25280, Saltillo Coahuila., en cumplimiento de la
          Ley Federal de Protección de Datos Personales en Posesión de los
          Particulares y se presenta como responsable del tratamiento de los datos
          personales que usted proporciona.
        </Parrafo>
        <Parrafo>
          Los datos personales que usted nos ha proporcionado directamente o a
          través de medios electrónicos han sido recabados y serán tratados, bajo
          los principios de licitud, consentimiento, información, calidad,
          finalidad, lealtad, proporcionalidad y responsabilidad, de conformidad
          con lo dispuesto por la Ley.
        </Parrafo>
        <Parrafo>
          Sus datos personales se encontrarán debidamente protegidos a través de
          medidas de seguridad tecnológicas, físicas y administrativas, previniendo
          el uso o divulgación indebida de los mismos.
        </Parrafo>
        <ParrafoBold>
          ¿Para qué recabamos y utilizamos sus datos personales?
        </ParrafoBold>
        <Parrafo>
          La información proporcionada será revisada por nuestro personal, para
          poder darle un mejor servicio, tomando en cuenta sus condiciones de
          salud, además se le enviará información importante para mantener el
          cuidado de su salud. Se les dará seguimiento a solicitudes de
          información de servicios y/o productos de nuestra empresa. Se podrán
          realizar cotizaciones, dar avisos urgentes sobre nuestros servicios,
          informar sobre cambios, nuevos productos o promociones, dar cumplimiento
          a obligaciones contraídas con nuestros clientes, evaluar la calidad del
          servicio.
        </Parrafo>
        <ParrafoBold>
          Datos personales que recabamos de forma directa:
        </ParrafoBold>
        <Parrafo>
          Nombre, dirección, ciudad, teléfono, celular, e-mail, fecha de
          nacimiento. Sus datos personales serán recopilados en nuestro formato de
          registro, hojas de consulta, cuando nos llame o nos visite para pedir
          informes y al contratar algún tipo de servicio.
        </Parrafo>
      </AvisoContainer> 
      </EntitledContainer>
      
      </HeadBg>

    </Wrapper>
  );
};

export default AvisoPrivacidad;