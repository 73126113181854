import React, { useRef } from "react";
import styled from "styled-components";
import { Text, TextPacifico, Wrapper } from "../../components/bodycomponents";
import Footer from "../../components/Footer";
import LastFooter from "../../components/LastFooter";
import NavigateBar from "../../components/NavigateBar";
import ServiceBody from "./ServiceBody";
import { useNavigate } from "react-router-dom";

const HeadBg = styled.div`
  padding-top: 30px;
  background: var(--verde_claro, #e7f2ee);
  height: 80vh;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: -1;
  @media (max-width: 768px) {
    height: 80vh;
  }
`;

const EntitledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 8%;
  z-index: 0;
  @media (max-width: 768px) {
    margin-top: 30%;
  }
`;

const Servicios = () => {
  const navigateto = useNavigate();
  const contacto = useRef();

  const handleClick = () => {
    navigateto("/#testimonials");
  };

  const handleClickContacto = () => {
    contacto.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Wrapper>
      <NavigateBar
        style={{
          marginBottom: "-7%",
          position: "absolute",
          top: "40px",
          left: "50%",
          translate: "-50%",
        }}
        handleClick={handleClick}
        handleClickContacto={handleClickContacto}
      />
      <HeadBg>
        <EntitledContainer>
          <Text
            style={{
              marginBottom: "-5px",
              fontSize: "2.1875rem",
              fontWeight: "700",
              color: "#6CAD9A",
              textAlign: "center",
            }}
          >
            Elige sentirte bien
          </Text>
          <TextPacifico
            style={{
              marginTop: "10px",
              color: "#26444D",
              fontSize: "4.0625rem",
            }}
          >
            desde hoy
          </TextPacifico>
        </EntitledContainer>
      </HeadBg>
      <ServiceBody />
      <Footer contacto={contacto} />
      <LastFooter />
    </Wrapper>
  );
};

export default Servicios;
