import React from "react";
import styled from "styled-components";
import { TitleBody } from "../../components/genericcomponents";
import icono1 from "../../Resources/Group 4069.svg";
import icono2 from "../../Resources/directions_walk_FILL1_wght300_GRAD0_opsz24 1.svg";
import icono3 from "../../Resources/water_full_FILL1_wght300_GRAD0_opsz24 1.svg";

const Container = styled.div`
  text-align: center;
  background-color: var(--verde_claro, #e7f2ee);
  padding: 3%;
  @media (max-width: 768px) {
    margin-top: 10%;
    padding-top: 10%;
    text-align: center;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  margin: 20px 15%;
  width: 70%;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack boxes vertically on small screens */
    align-items: center; /* Center boxes horizontally on small screens */
    justify-content: center;
  }
`;

const Box = styled.div`
  flex: 1;
  width: 13.875rem;
  height: 7.5625rem;
  background-color: ${(props) => props.backgroundColor};
  padding: 20px;
  border-radius: 10px;
  border-radius: 1.875rem;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
  white-space: wrap;
  text-align: center;
  margin-left: 1%;
  @media (max-width: 768px) {
    width: 100%; /* Take up full width on small screens */
    margin: 5% 5%; /* Adjust margin between boxes for smaller screens */
  }
`;

const Icon = styled.img`
  width: 3.9375rem;
  height: 3.9375rem;
`;

const Text = styled.p`
  color: var(--blanco, #fff);
  text-align: center;
  /* Poppins/text-21pt-medium */
  font-family: Poppins;
  font-size: 0.92rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.03938rem;
`;

const BottomText = styled.p`
  color: var(--verde_oscuro, #26444d);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03938rem;
`;

function InfoRecomendacion() {
  return (
    <Container>
      <TitleBody
        style={{
          fontSize: "1.3135rem",
          textTransform: "uppercase",
          lineHeight: "0",
        }}
      >
        Comienza hoy
      </TitleBody>
      <TitleBody style={{ marginBottom: "4%" }}>
        Aplica estos sencillos pasos:
      </TitleBody>
      <BoxContainer>
        <Box backgroundColor="#26444D">
          <Icon src={icono3}></Icon>
          <Text>Toma 2 L de agua al día</Text>
        </Box>
        <Box backgroundColor="#6CAD9A">
          <Icon src={icono2}></Icon>
          <Text>Camina 30 minutos diarios</Text>
        </Box>
        <Box backgroundColor="#FB7E62">
          <Icon src={icono1}></Icon>
          <Text>Evita grasas animales</Text>
        </Box>
      </BoxContainer>
      <BottomText>
        Después acude con nosotros para diseñar tu plan de alimentación
        personalizado.
      </BottomText>
    </Container>
  );
}

export default InfoRecomendacion;
