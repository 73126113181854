import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, TitleH2 } from "../../components/bodycomponents";

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2% -3%;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column; /* Cambiar a columna en dispositivos móviles */
    align-items: center; /* Alinear al inicio en dispositivos móviles */
    width: 110%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 200px;
  border-radius: 1.875rem;
  background: var(--blanco, #fff);
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
  height: 35vw;
  overflow: hidden;
  max-height: 450px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const TextContainer = styled.div`
  flex: 2;
  margin: 0 3%;
  border-radius: 1.875rem;
  background: var(--blanco, #fff);
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
  height: 35vw;
  max-height: 450px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 90%; /* Ancho completo en dispositivos móviles */
  }
`;

const Imagen = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 1.875rem 1.875rem 0rem 0rem;
  background: lightgray;
  object-fit: fill;
  object-position: 50% 50%;
`;

const ImagenCorta = styled.img`
  width: 140%;
  height: 200px;
  border-radius: 1.875rem 1.875rem 0rem 0rem;
  background: lightgray;
  object-fit: cover;
  object-position: 50% 50%;
`;

const TextWrap = styled.div`
  padding: 0 5%;
  h2 {
    line-height: 1rem;
    margin-bottom: 0;
  }
`;

const PageNumber = styled.p`
  color: var(--verde_medio, #6cad9a);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.03188rem;
  margin: 3px 5%;
`;

const Carousel = ({ images, currentPage, setCurrentP }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [prevImage, setPrevImage] = useState(1);

  useEffect(() => {
    // Cambiar currentImage y prevImage cuando currentPage cambie
    setCurrentImage(currentPage % images.length);
    setPrevImage((currentPage + 1 + images.length) % images.length);

    // Cambiar automáticamente de página cada 5 segundos
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
      setPrevImage((image) => (image + 1) % images.length);
      setCurrentP((image) => (image + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [images, currentPage]);

  const checkIsEight = (suma) => {
    if (suma === 8) {
      return 1;
    } else {
      return suma;
    }
  };
  return (
    <CarouselContainer>
      <TextContainer>
        <Imagen
          src={images[currentImage].src}
          alt={images[currentImage].title}
        />
        <PageNumber>{`${currentPage + 1}/7`}</PageNumber>
        <TextWrap>
          <TitleH2 style={{ fontSize: "1.15rem" }}>
            {images[currentImage].title}
          </TitleH2>
          <Text style={{ fontSize: "1.15rem" }}>
            {images[currentImage].description}
          </Text>
        </TextWrap>
      </TextContainer>
      <ImageContainer>
        <ImagenCorta
          src={images[prevImage].src}
          alt={images[prevImage].title}
        />
        <PageNumber>{`${checkIsEight(currentPage + 2)}/7`}</PageNumber>
        <TitleH2 style={{ fontSize: "1.15rem", paddingLeft: "5%" }}>
          {images[prevImage].title}
        </TitleH2>
      </ImageContainer>
    </CarouselContainer>
  );
};
export default Carousel;
