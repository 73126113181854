import React from "react";
import styled from "styled-components";
import logo from "../Resources/Vector.svg";
import iconFace from "../Resources/LogoMediaFacebook.svg";
import iconInsta from "../Resources/LogoMediaInsta.svg";
import locationIcon from "../Resources/location_on.svg";
import phoneIcon from "../Resources/call.svg";
import whatIcon from "../Resources/icons8-whatsapp 1.svg";
import arrobaIcon from "../Resources/alternate_email.svg";
import { useNavigate } from "react-router-dom";

const FooterContainer = styled.footer`
  background: var(--verde_medio, #6cad9a);
  color: white;
  padding: 20px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 20px 10px 40px;
  }
`;

const LogoColumn = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const AboutColumn = styled.div`
  flex: 1;
  margin-right: 2%;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-top: 20px;
  }
`;

const ContactColumn = styled.div`
  flex: 2;

  @media (max-width: 768px) {
    flex: 1;
  }
`;

const Logo = styled.img`
  width: 7vw;

  @media (max-width: 768px) {
    width: 30vw;
  }
`;

const Title = styled.h4`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
`;

const Paragraph = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
`;

const Address = styled.address`
  font-weight: 500;
  font-size: 0.75rem;
  font-style: normal;
`;

const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 10%;
  margin-top: 1%;
  margin-bottom: 0;

  @media (max-width: 768px) {
    justify-content: center;
    margin-right: 0;
    display: none;
  }
`;
const SocialMediaIconsMobile = styled.div`
  display: none;
  justify-content: end;
  margin-right: 10%;
  margin-top: 1%;
  margin-bottom: 0;

  @media (max-width: 768px) {
    justify-content: center;
    margin: 30px 0 0 0;
    display: flex;
  }
`;
const SocialMIcon = styled.img`
  width: 2.5%;
  margin-left: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 10%;
    margin: 5% 20px;
    cursor: pointer;
  }
`;

const FooterContainerInfo = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    margin: 5%;
  }
`;

const IconList = styled.img`
  text-decoration: none;
  cursor: pointer;
`;

const IconTextLine = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  @media (max-width: 768px) {
    flex: 1;
    justify-content: space-evenly;
    margin: 5%;
  }
`;

const EmailLink = styled.a`
  color: inherit;
  text-decoration: none;
  font-size: inherit;
`;

const Footer = (props) => {
  const onClickIcon = (id) => {
    if (id === "face") {
      window.open("https://m.facebook.com/PilticSaltillo", "_blank");
    }
    if (id === "insta") {
      window.open(
        "https://instagram.com/piltic.medic?utm_source=qr&igshid=MzNlNGNkZWQ4Mg==",
        "_blank"
      );
    }
  };

  return (
    <FooterContainer ref={props.contacto}>
      <SocialMediaIcons>
        <SocialMIcon
          id={"face"}
          src={iconFace}
          onClick={(e) => onClickIcon(e.target.id)}
        />
        <SocialMIcon
          id={"insta"}
          src={iconInsta}
          onClick={(e) => onClickIcon(e.target.id)}
        />
      </SocialMediaIcons>
      <FooterContainerInfo>
        <LogoColumn>
          <Logo src={logo} alt="Logo de Piltic Medic Spa" />
        </LogoColumn>
        <SocialMediaIconsMobile>
          <SocialMIcon
            id={"face"}
            src={iconFace}
            onClick={(e) => onClickIcon(e.target.id)}
          />
          <SocialMIcon
            id={"insta"}
            src={iconInsta}
            onClick={(e) => onClickIcon(e.target.id)}
          />
        </SocialMediaIconsMobile>
        <AboutColumn>
          <Title>Acerca de</Title>
          <Paragraph>
            Piltic Medic Spa ofrece un manejo médico integral del peso con
            planes de alimentación alineados a las necesidades de cada paciente.
          </Paragraph>
        </AboutColumn>
        <ContactColumn>
          <Title>Contacto</Title>
          <Address>
            <IconTextLine style={{ marginBottom: "2%" }}>
              <IconList src={locationIcon} />
              Monclova 1621, Col. República Pte., C.P. 25280, Saltillo, Coah.{" "}
            </IconTextLine>
            <IconTextLine>
              <IconList src={phoneIcon} />
              <EmailLink href="tel:8444165301">(844) 416 5301</EmailLink>
              <IconList src={whatIcon} style={{ marginLeft: "20px" }} />
              <EmailLink href="tel:8445888138">(844) 588 8138</EmailLink>
            </IconTextLine>
            <IconTextLine>
              <IconList src={arrobaIcon} />
              <EmailLink href="mailto:piltic.medic.tratamientos@gmail.com">
                piltic.medic.tratamientos@gmail.com
              </EmailLink>
            </IconTextLine>
          </Address>
        </ContactColumn>
      </FooterContainerInfo>
    </FooterContainer>
  );
};

export default Footer;
