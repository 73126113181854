import React from "react";
import styled from "styled-components";
import catalogIcon from "../../Resources/Group 35.svg";
import icon1 from "../../Resources/home/Frame.svg";
import icon2 from "../../Resources/home/Frame (1).svg";
import icon3 from "../../Resources/home/Frame (2).svg";
import icon4 from "../../Resources/home/Frame (3).svg";
import icon5 from "../../Resources/home/Frame (4).svg";
import icon6 from "../../Resources/home/Frame (5).svg";
import icon7 from "../../Resources/home/Frame (6).svg";
import { useNavigate } from "react-router-dom";

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  border-top: 1px dotted #ccc;
  background-color: white;
  margin-bottom: 5%;
`;

const Column = styled.div`
  flex: 1;
  text-align: center;
  padding: 20px;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1.5px dotted #c4c4c4;
  border-bottom: 1.5px dotted #c4c4c4;
  cursor: pointer;
  @media (max-width: 768px) {
    flex: 1;
    &:not(:last-child) {
      border-right: none;
    }
    flex: 0;
    border-right: none;
  }
`;

const Image = styled.img`
  width: 80%;
  max-width: 12.5rem;
  border-radius: 50%;
`;

const Title = styled.p`
  color: #26444d;
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.03938rem;
`;

const ServicesSection = () => {
  const navigateTo = useNavigate();

  const handleClick = () => {
    navigateTo("/servicios");
    window.scrollTo(0, 0);
  };

  return (
    <Section onClick={handleClick}>
      <Column>
        <Image src={icon1} alt="Catálogo de productos" />
        <Title>estudios</Title>
      </Column>
      <Column>
        <Image src={icon2} alt="Pedidos hasta tu negocio" />
        <Title>planes alimenticios</Title>
      </Column>
      <Column>
        <Image src={icon3} alt="Atención personalizada" />
        <Title>tips y consumos</Title>
      </Column>
      <Column>
        <Image src={icon4} alt="Atención personalizada" />
        <Title>mesoterapia</Title>
      </Column>
      <Column>
        <Image src={icon5} alt="Atención personalizada" />
        <Title>ejercicios localizados</Title>
      </Column>
      <Column>
        <Image src={icon6} alt="Atención personalizada" />
        <Title>DEPILACIÓN</Title>
      </Column>
      <Column>
        <Image src={icon7} alt="Atención personalizada" />
        <Title>masajes</Title>
      </Column>
    </Section>
  );
};

export default ServicesSection;
