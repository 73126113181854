import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import background from "../../Resources/background.png";
import NavigateBar from "../../components/NavigateBar";
import Hero from "./Hero";
import ServicesSection from "./ServicesSection";
import AboutUs from "./AboutUs";
import InfoRecomendacion from "./InfoRecomendacion";
import Tratamientos from "./Tratamientos";
import Testimonios from "./Testimonios";
import LastFooter from "../../components/LastFooter";
import Footer from "../../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  font-family: "Poppins";
  color: #26444d;
  max-width: 1920px;
  margin: 0 auto;
`;

const HeadBg = styled.div`
  padding-top: 3%;
  background-image: url(${background});
  background-color: #dde6e5;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 700vh;
  position: relative;
  height: 670px;
  @media (max-width: 768px) {
    background-position-y: 20%;
    height: 140vw;
  }
`;

const Home = () => {
  const location = useLocation();
  const testimonials = useRef();
  const contacto = useRef();

  const handleClick = () => {
    testimonials.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickContacto = () => {
    contacto.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (location.hash === "#testimonials") {
      testimonials.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <Wrapper>
      <HeadBg>
        <NavigateBar
          handleClick={handleClick}
          handleClickContacto={handleClickContacto}
        />
        <Hero />
      </HeadBg>
      <ServicesSection />
      <AboutUs handleClickContacto={handleClickContacto} />
      <InfoRecomendacion />
      <Tratamientos />
      <Testimonios reference={testimonials} />
      <Footer contacto={contacto} />
      <LastFooter />
    </Wrapper>
  );
};

export default Home;
