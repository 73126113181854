import React from "react";
import styled from "styled-components";
import Pregunta from "./Pregunta";
import { Wrapper } from "../../components/bodycomponents";

const PreguntasList = [
  {
    id: 1,
    pregunta: "¿Esta dieta tiene rebote?",
    respuesta:
      "No, el rebote sucede cuando se realiza un cambio de alimentación extrema y así mismo se vuelve a los hábitos anteriores de     golpe, con estos menús se aprende a comer y adquirir hábitos comiendo de todo y cantidades libres de una forma natural es por eso que no sucede el rebote.",
  },
  {
    id: 2,
    pregunta: "¿Se puede tratar la piel flácida?",
    respuesta:
      "Si, dependiendo del deterioro que ha sufrido la piel se puede ayudar con varias alternativas, lo más importante es tratarla a     tiempo antes de ir perdiendo cada vez más peso. Se puede tratar con Mesoterapia, Radiofrecuencia o EMS Supramax",
  },
  {
    id: 3,
    pregunta: "¿Se puede quitar la celulitis?",
    respuesta:
      "Si, requiere muchos cambios desde la alimentación, la ingesta de agua, no ser sedentario y la ayuda de tecnología médica     como: Vacuumterapia, Manto detox, Radiofrecuencia, Cavitación, Lipoláser y EMS Supramax",
  },
  {
    id: 4,
    pregunta: "¿Puedo mantener el peso perdido?",
    respuesta:
      "Claro, en nuestro sistema contamos con 2 etapas, la primera de reducción de peso y la segunda de mantenimiento, donde     seguimos enseñando hábitos alimenticios para mantener el peso ideal de cada paciente. Dando opcion balanceada para     mantenerse, ejercitarse y comer de todo,",
  },
  {
    id: 5,
    pregunta: "¿Hay alternativas/productos para quemar grasa?",
    respuesta:
      'La quema de grasa no existe como tal con algún producto o alguna máquina, lo que realizan la aparatología médica es ayudar a     vaciar la grasa de los adipositos y llevarla al torrente sanguíneo para que de esta forma se pueda eliminar por medio de una dieta     hipocalórica así mismo los productos "reductivos" lo que hacen es generar termogénesis para aumentar temperatura corporal y     así promover la quema de grasas por medio de una dieta. Es importante saber que SOLO no funciona.',
  },
  {
    id: 6,
    pregunta: "¿Para qué me sirve el Modelo Corporal con aparatología?",
    respuesta:
      "Es una aplicación de aparatología médica para poder reducir medidas por zona localizada, sirve mucho en mujeres que     quieren bajar de peso sin bajar de zonas que no queremos como busto o glúteo, esto ayuda a localizar poder bajar de zonas     difíciles como abdomen, espalda, cadera o pierna. En hombres también se aplica para zonas donde está más concentrada la    grasa.",
  },
  {
    id: 7,
    pregunta: "¿La criolipolisis dura para siempre?",
    respuesta:
      "Este tratamiento abarca por sesión un 30 % de grasa corporal definitivo, pero como sigue quedando grasa residual persiste la     posibilidad de aumentar la grasa en la zona tratada, es por eso que este tratamiento se recomienda cuando ya se ha perdido     peso, se está más cerca de la meta, o se tiene el peso ideal pero con áreas con grasa difícil de quitar. Una vez que el paciente     comprenda que no es un tratamiento para bajar de peso se puede continuar, solamente son 3 sesiones ÚNICAS",
  },
  {
    id: 8,
    pregunta: "¿Los tratamientos de E-light son invasivos?",
    respuesta:
      "No son invasivos ya que se trata la piel solamente de manera externa, al ser una luz de largas longitudes si hay varios cuidados     que hacer al estar en el tratamiento, tales como; no uso de tratamiento farmacológico para acné, no asolearse, no realizarno     sobre piel dañada y mantener un cuidado correcto en casa",
  },
];

const Preguntas = () => {
  return (
    <Wrapper style={{ marginBottom: "5%", marginTop: "5%" }}>
      {PreguntasList.map((pregunta) => (
        <Pregunta key={pregunta.id} pregunta={pregunta} />
      ))}
    </Wrapper>
  );
};

export default Preguntas;
