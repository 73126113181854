import React from "react";
import styled from "styled-components";
import { Wrapper } from "../../components/bodycomponents";
import imagen1 from "../../Resources/tratamientos/73357b941b1fa0ad64af8ddbc4eda557.png";
import imagen2 from "../../Resources/tratamientos/f7baf6cef0856f2e06e26db1d9a5c4d2.png";
import imagen3 from "../../Resources/tratamientos/0814da0923d9b0ef6eb49ee5590df60f.png";
import imagen4 from "../../Resources/tratamientos/85899691b7873f7591dc15c88303659c.png";
import ItemBlock from "./ItemBlock";

const TratamientosItem = [
  {
    id: 1,
    imagen: imagen1,
    titulo: "RADIOFRECUENCIA",
    parrafo:
      "Tecnología para tratar la laxitud de la piel, produce un efecto similar al lifting gracias al calor intradérmico que produce más colágeno y elastina. \n Para ayudar a eliminar arrugas, flacidez leve y mejorar la textura de la piel.",
  },
  {
    id: 2,
    imagen: imagen2,
    titulo: "E-LIGHT",
    parrafo:
      "Tratamiento que ayuda a la eliminación de arañas vasculares, acné, rejuvenecimiento facial, manchas, rosácea y arrugas por medio de luz pulsada combinada con radiofrecuencia.",
  },
  {
    id: 3,
    imagen: imagen3,
    titulo: "MICRODERMOABRASIÓN",
    parrafo:
      "Es una exfoliación profunda de la epidermis para renovar la piel, disminuir arrugas, manchas y mejorar su textura.",
  },
  {
    id: 4,
    imagen: imagen4,
    titulo: "DERMAPUNT",
    parrafo:
      "Dispositivo con microagujas que ayuda a la producción de colágeno por sus microheridas y tratamiento adjunto. \nMejora la calidad de la piel, hidrata, suaviza arrugas, manchas, cicatrices, poros y acné.",
  },
];

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const ItemTratamiento = () => {
  return (
    <ItemsContainer>
      {TratamientosItem.map((tratamiento) => (
        <ItemBlock key={tratamiento.id} tratamiento={tratamiento} />
      ))}
    </ItemsContainer>
  );
};

export default ItemTratamiento;
