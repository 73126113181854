import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  border-radius: 1.875rem 0rem 1.875rem 1.875rem;
  background: var(--blanco, #fff);
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
  margin: 2% 2%;
  @media (max-width: 768px) {
    width: 100%;
    margin: 5% 0;
  }
`;
const Image = styled.img`
  height: 7.875rem;
  width: 23.8rem;
  object-fit: cover;
  border-radius: 1.875rem 0rem 0rem 0rem;
  @media (max-width: 768px) {
    width: 119%;
  }
`;

const Titulo = styled.p`
  color: var(--verde_oscuro, #26444d);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0;
  letter-spacing: 0.03938rem;
  padding: 0 2rem;
  margin-top: 10%;
`;

const Parrafo = styled.p`
  color: var(--verde_oscuro, #26444d);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02813rem;
  padding: 0 2rem;
  margin-top: 0;
  margin-bottom: 5%;
`;

const ItemBlock = (props) => {
  return (
    <Wrapper key={props.tratamiento.id}>
      <Image src={props.tratamiento.imagen} />
      <Titulo>{props.tratamiento.titulo}</Titulo>
      <Parrafo>{props.tratamiento.parrafo}</Parrafo>
    </Wrapper>
  );
};
export default ItemBlock;
