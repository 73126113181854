import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text } from "../../components/bodycomponents";
import Carousel from "./Carrousel";

import pic1 from "../../Resources/tratamientos/reel/4.jpg";
import pic2 from "../../Resources/tratamientos/reel/5.jpg";
import pic3 from "../../Resources/tratamientos/reel/6.jpg";
import pic4 from "../../Resources/tratamientos/reel/7.jpg";
import pic5 from "../../Resources/tratamientos/reel/8.jpg";
import pic6 from "../../Resources/tratamientos/reel/9.jpg";
import pic7 from "../../Resources/tratamientos/reel/10.jpg";

import circle1 from "../../Resources/tratamientos/reel/circle1.svg";
import circle2 from "../../Resources/tratamientos/reel/circle2.svg";

const images = [
  {
    src: pic1,
    title: "ULTRA CAVITACIÓN",
    description:
      "Empleo de ondas de ultrasonido que disuelven las células grasas enviándolas al torrente sanguíneo para ser eliminadas. Disminuye volumen y celulitis sin causar dolor.",
  },
  {
    src: pic2,
    title: "RADIOFRECUENCIA",
    description:
      "Transmite calor intradérmico que genera la producción de colágeno y elastina. Mejora la piel, reduce la flacidez y aporta firmeza en el área tratada.",
  },
  {
    src: pic3,
    title: "LIPOLÁSER",
    description:
      "Remoldea la figura corporal disolviendo adiposidades con la fuerza del láser, enviando la grasa al torrente sanguíneo para su eliminación. Da firmeza a la piel por regeneración del tejido.",
  },
  {
    src: pic4,
    title: "MANTO DETOX",
    description:
      "Un tratamiento de termosudoración que aumenta los efectos de procedimientos moldeadores. Ayuda a eliminar toxinas por medio del sudor, mejora el riego sanguíneo y el drenaje linfático.",
  },
  {
    src: pic5,
    title: "VACUUMTERAPIA",
    description:
      "Un tratamiento que activa el sistema linfático, ayudando a eliminar toxinas del cuerpo. Mejora la circulación y depura la congestión de líquidos retenidos para disminuir la celulitis.",
  },
  {
    src: pic6,
    title: "CRIOLIPÓLISIS",
    description:
      "Es un tratamiento de muerte celular programada (apoptosis) que localiza células grasas y las congela para evitar la nueva acumulación de grasa en la zona tratada.",
  },
  {
    src: pic7,
    title: "EMS SCULPTING",
    description:
      "Tecnología HI-EMT que crea contracciones musculares en una zona específica. Diseñada para el desarrollo y fortalecimiento de músculos, así como la pérdida de grasa en el área.",
  },
];

const Wrapper = styled.div`
  max-width: 1620px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TratamientosCorpSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: left;
  padding: 2% 15%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 3%;
  margin-left: 5%;
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Icon = styled.img`
  width: 8px; /* Ajusta el tamaño del icono según tus preferencias */
  height: 20px;
  border-radius: 50%; /* Esto hace que el icono tenga forma de círculo */
  margin: 0 5px; /* Espacio entre los iconos */
  cursor: pointer;
  @media (max-width: 768px) {
    margin-left: 8%;
    height: 30px;
  }
`;

const Title = styled.h2`
  color: var(--verde_oscuro, #26444d);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06563rem;
  margin-bottom: 0;
`;

const CarouselIconWrap = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 5%;
    margin-left: 0;
    justify-content: center;
    width: 100%;
  }
`;

const TratamientosCorporales = () => {
  const [Pages, settPages] = useState(new Array(7).fill(""));
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const setCurrentP = (page) => {
    setCurrentPage(page);
  };

  return (
    <Wrapper>
      <TratamientosCorpSection>
        <Title>Tratamientos corporales</Title>
        <Text style={{ textAlign: "justify" }}>
          Aplicación de aparatología grado médico en diferentes áreas del cuerpo
          para localizar reducción de grasa, flacidez y celulitis. Logrando
          resultados espectaculares junto a un plan alimenticio.
        </Text>
        <CarouselIconWrap>
          <Carousel
            images={images}
            currentPage={currentPage}
            setCurrentP={setCurrentP}
          />
          <IconWrapper>
            {Pages.map((_, index) => (
              <Icon
                key={index}
                src={currentPage === index ? circle1 : circle2}
                alt={`Page ${index}`}
                onClick={() => handlePageChange(index)}
              />
            ))}
          </IconWrapper>
        </CarouselIconWrap>
      </TratamientosCorpSection>
    </Wrapper>
  );
};

export default TratamientosCorporales;
