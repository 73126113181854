import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom"; 
import aviso from "../Resources/AVISO DE PRIVACIDAD.pdf";

const FooterSeparator = styled.div`
  background-color: #26444d;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const CopyrightText = styled.div`
  padding: 10px;
  font-size: 0.9rem;
  text-align: center;

  @media (min-width: 768px) {
    flex: 1;
    padding-left: 8%;
    text-align: left;
  }
`;

const PrivacyLink = styled.a`
  text-align: center;
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 10px;

  @media (min-width: 768px) {
    flex: 1;
    text-align: right;
    margin-top: 0;
    margin-right: 10%;
  }
`;

const MobileReverseFooter = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    background-color: #26444d;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const StyledNavLink = styled(NavLink)`
  text-align: center;
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 10px;

  &.active { /* Estilos cuando el enlace está activo */
    font-weight: bold; /* Ejemplo */
  }

  &:hover { /* Estilos al pasar el mouse sobre el enlace */
    text-decoration: underline; /* Ejemplo */
  }

  @media (min-width: 768px) {
    flex: 1;
    text-align: right;
    margin-top: 0;
    margin-right: 10%;
  }
`;

const LastFooter = () => {

  return (
    <>
      <FooterSeparator>
        <CopyrightText>
          © Piltic Medic Spa 2023. Todos los derechos reservados.
        </CopyrightText>
        <StyledNavLink to="/Aviso_de_privacidad">Aviso de privacidad</StyledNavLink>
      </FooterSeparator>
      <MobileReverseFooter>
      <StyledNavLink to="/Aviso_de_privacidad">Aviso de privacidad</StyledNavLink>
        <CopyrightText>
          © Piltic Medic Spa 2023. Todos los derechos reservados.
        </CopyrightText>
      </MobileReverseFooter>
    </>
  );
};
export default LastFooter;
