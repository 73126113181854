import React, { useState } from "react";
import styled from "styled-components";
import openIcon from "../../Resources/faq/Group 4055.svg";
import closedIcon from "../../Resources/faq/Group 4046.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  border-radius: 0.9375rem;
  background: var(--blanco, #fff);
  width: 50%;
  margin: 1.5% 25%;
  padding: 1% 1%;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 768px) {
    /* Estilos para dispositivos móviles */
    width: 85%;
    margin: 1.5% 5%;
    padding: 1% 2%;
  }
`;

const Titulo = styled.p`
  color: var(--verde_oscuro, #26444d);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.03938rem;
`;

const Respuesta = styled.p`
  color: var(--verde_oscuro, #26444d);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02813rem;
  max-height: ${({ isOpen }) => (isOpen ? "1000px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const Icon = styled.img`
  cursor: pointer;
`;

const ContenedorPreguntaIcono = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Pregunta = (props) => {
  const [respuestaVisible, setRespuestaVisible] = useState(false);

  const toggleRespuesta = () => {
    setRespuestaVisible(!respuestaVisible);
  };

  return (
    <Wrapper>
      <ContenedorPreguntaIcono>
        <Titulo>{props.pregunta.pregunta}</Titulo>
        <Icon
          onClick={toggleRespuesta}
          src={respuestaVisible ? openIcon : closedIcon}
        />
      </ContenedorPreguntaIcono>

      {respuestaVisible && (
        <Respuesta isOpen={respuestaVisible}>
          {props.pregunta.respuesta}
        </Respuesta>
      )}
    </Wrapper>
  );
};

export default Pregunta;
