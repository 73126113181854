import React from "react";
import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: Poppins;
  color: #26444d;
  max-width: 1920px;
  margin: 0 auto;
  z-index: -1;
`;

export const TitleH1 = styled.h1`
  color: var(--verde_oscuro, #26444d);
  /* Poppins/title-55pt-bold */
  font-family: Poppins;
  font-size: 3.4375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.10313rem;
`;

export const TitleH2 = styled.h2`
  color: var(--verde_oscuro, #26444d);
  /* Poppins/title-35pt-bold */
  font-family: Poppins;
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06563rem;
`;

export const Text = styled.p`
  color: var(--verde_oscuro, #26444d);
  /* Poppins/text-21pt-medium */
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03938rem;
`;

export const TextPacifico = styled.p`
  color: var(--verde_brillante, #d7dd7e);
  font-family: Pacifico;
  font-size: 2.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.125rem; /* 52.308% */
  letter-spacing: 0.12188rem;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const TitlePacifico = styled.p`
  color: var(--verde_oscuro, #26444d);
  font-family: Pacifico;
  font-size: 1.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.125rem; /* 79.07% */
  letter-spacing: 0.08063rem;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ImagenInformativa = styled.img`
  border-radius: 2.5rem 0rem 2.5rem 2.5rem;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.3);
`;

export const HeroButton = styled.a`
  padding: 17px 40px;
  color: #fff;
  text-align: center;

  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  letter-spacing: 0.03938rem;
  border-radius: 1.875rem;
  background: linear-gradient(180deg, #fb7e62 0%, #fb7e62 100%);

  /* shadow-boton */
  box-shadow: 0px 3px 7px 0px rgba(21, 37, 0, 0.15);
  width: 12.5rem;
  height: 3.125rem;
  cursor: pointer;

  text-transform: uppercase;

  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
    padding: 17px 25%;
  }
`;
