import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  TitlePacifico,
  TextPacifico,
  Text,
} from "../../components/bodycomponents";
import TestimonioBlock from "./TestimonioBlock";
import foto1 from "../../Resources/testimonios/411c134749a1166fc6669018433572d1.jpg";
import foto2 from "../../Resources/testimonios/66f17a0740ab544705874d3b117aa3fd.jpg";
import foto3 from "../../Resources/testimonios/7c94f0319aea26f5db7d28a31cd0331c.png";
import foto4 from "../../Resources/testimonios/86c739185586a6e892684b1c6c525fef.jpg";
import foto5 from "../../Resources/testimonios/8c72b4ab0d795d618930542752b45396.jpg";
import foto6 from "../../Resources/testimonios/a20b40454abcc7054022628133a84abd.jpg";
import comillas1 from "../../Resources/testimonios/Group.svg";
import comillas2 from "../../Resources/testimonios/Group (1).svg";

const testimonios = [
  {
    foto: foto5,
    nombre: "Mary Ros De Labra",
    parrafo:
      "Soy paciente del Dr. Arreola desde hace 25 años, ahora también de la Doctora Mariana, excelentes resultados he obtenido al tratarme con ellos; he logrado mantener figura estética, y después de cada embarazo (4 embarazos) con la dieta y modelo que ofrecen, vuelvo a recuperar mi peso y talla.",
  },
  {
    foto: foto1,
    nombre: "Judith Alejandra",
    parrafo:
      "Excelentes resultados y en poco tiempo si sigues las indicaciones! No rebote👏",
  },
  {
    foto: foto3,
    nombre: "Denisse Lopez",
    parrafo:
      "Excelente atención y servicio! Los resultados que obtuve en mi facial después de presentar problemas por el uso del cubrebocas desaparecieron muy rápido! \n  Totalmente recomendado⭐⭐⭐⭐⭐",
  },
  {
    foto: foto4,
    nombre: "Karina Ayala",
    parrafo:
      "Excelente todo!! Las dietas de la dra. Mariana, la atención de Irma y los tratamientos corporales y faciales con Meche y Elisa. Recomiendo Piltic al 100%",
  },
  {
    foto: foto2,
    nombre: "Jorge Adh",
    parrafo:
      "Después de casi 2 meses y 15 kilos... Gracias! Piltic Medic Spa por ayudarme a cambiar mis hábitos de vida durante este tiempo!      Desde hace mucho tiempo había tenido la intención de comenzar a cuidar mi cuerpo pero por falta de desiciones y tiempo no se había podido 🙃...",
  },
  {
    foto: foto6,
    nombre: "Vy Rodríguez",
    parrafo:
      "super profesionales en un ambiente cálido , excelentes resultados!",
  },
  // Agrega más testimonios aquí...
];
const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #26444d; /* Color de fondo del cuadro */
  width: 70%; /* Ancho del cuadro (3/4 del ancho de la pantalla) */
  left: 0; /* Alineado a la izquierda */
  margin-bottom: 5%;
  @media (max-width: 768px) {
    width: 100%; /* Cambia el ancho para dispositivos móviles y tablets */
    flex-wrap: wrap;
    margin-left: 0;
  }
`;

const Content = styled.div`
  /* Estilos para el contenido dentro del cuadro */
  color: white;
  text-align: center;
  position: relative; /* Agregamos posición relativa */
  z-index: 1;
`;
const TestimonioContent = styled.div`
  width: 150%;
  z-index: 2;
  top: 0;
  transform: translateX(45%); /* Centramos horizontalmente */
  margin-top: 3%;
  @media (max-width: 768px) {
    width: 80%; /* Cambia el ancho para dispositivos móviles y tablets */
    transform: translateX(0);
  }
`;

const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-left: 10%;
  width: 100%;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const TopLeftImage = styled.img`
  position: absolute;
  top: -15px;
  left: 60px;
  width: 10vw;
  max-width: 100px;
`;

const BottomRightImage = styled.img`
  position: absolute;
  bottom: -30px;
  right: 40px;
  width: 10vw;
  max-width: 100px;
  z-index: -1;
`;

const TestimonioContainer = styled.div`
  position: relative; /* Agregamos posición relativa al contenedor */
  padding: 3% 5%;
  margin-bottom: 5%;
  border-radius: 0.9375rem;
  background: var(--blanco, #fff);
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
  z-index: -2;
`;
function Testimonios(props) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonios.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  return (
    <SectionContainer ref={props.reference}>
      <ContentDiv>
        <Content>
          <Text
            style={{
              marginBottom: "-7px",
              color: "#fff",
              fontWeight: "700",
              fontSize: "1.5rem",
            }}
          >
            Lo que nuestros clientes
          </Text>
          <TextPacifico style={{ marginTop: "0", fontSize: "2.3rem" }}>
            dicen de nosotros
          </TextPacifico>
        </Content>
      </ContentDiv>
      <TestimonioContent>
        <TestimonioContainer>
          <TopLeftImage src={comillas1} alt="Imagen Superior Izquierda" />
          <TestimonioBlock testimonio={testimonios[currentIndex]} />
        </TestimonioContainer>

        <TestimonioContainer>
          <TestimonioBlock
            testimonio={testimonios[(currentIndex + 1) % testimonios.length]}
          />
          <BottomRightImage src={comillas2} alt="Imagen Inferior Derecha" />
        </TestimonioContainer>
      </TestimonioContent>
    </SectionContainer>
  );
}

export default Testimonios;
