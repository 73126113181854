import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  margin: 1%;
  padding: 35px;
  border-radius: 1.875rem;
  background: var(--blanco, #fff);
  box-shadow: 0px 2.18954px 10.94771px 0px rgba(0, 0, 0, 0.15);
  height: 22rem;
  @media screen and (max-width: 768px) {
    /* Estilos para dispositivos móviles */
    width: 75vw;
    margin: 2% 3%;
    padding: 2% 35px;
    text-align: center;
  }
`;

const Icon = styled.img`
  width: 45%;
  margin-bottom: 0;
`;

const Title = styled.p`
  color: var(--verde_oscuro, #26444d);
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.05813rem;
  margin-bottom: 0;
  margin-top: 0;
`;

const Description = styled.p`
  color: var(--verde_oscuro, #26444d);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02813rem;
  margin-top: 0;
`;

const ScuareBlock = ({ ScuareBlocksData, style }) => {
  return (
    <Wrapper style={style}>
      <Icon src={ScuareBlocksData.icon} alt={ScuareBlocksData.title} />
      <Title>{ScuareBlocksData.title}</Title>
      <Description>{ScuareBlocksData.description}</Description>
    </Wrapper>
  );
};

export default ScuareBlock;
