import React from "react";
import styled from "styled-components";
import { HeroButton } from "../../components/bodycomponents";
import Icon1 from "../../Resources/live_help_FILL0_wght400_GRAD0_opsz24.svg";
import Icon2 from "../../Resources/stethoscope_FILL1_wght400_GRAD0_opsz24.svg";
import Icon3 from "../../Resources/target_FILL1_wght400_GRAD0_opsz24.svg";
import TratamientosImage from "../../Resources/7f8f17e5b4ac9d0f3cb1eb2ebc14f869.jpg";

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5%;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: fit-content;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack elements vertically on small screens */
  }
`;

const LeftColumn = styled.div`
  flex: 0 0 100%;
  max-width: 480px;

  @media (max-width: 768px) {
    max-width: 100%; /* Take up full width on small screens */
    text-align: center;
  }
`;

const Image = styled.img`
  max-width: 450vw;
  border-radius: 0rem 2.5rem 2.5rem 2.5rem;
  background: url(<path-to-image>), lightgray 50% / cover no-repeat;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.3);
  width: 35vw;
  height: 450px;
  object-fit: cover;
  flex: 0 0 50%;

  @media (max-width: 768px) {
    margin-top: 10%;
    max-width: 100%; /* Make the image take up full width on small screens */
    height: auto; /* Allow the height to adjust proportionally */
    width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 0 0 100%;
  max-width: 450px;
  padding: 0 0;
  flex: 0 0 50%;

  @media (max-width: 768px) {
    max-width: 100%; /* Take up full width on small screens */
  }
`;

const Title = styled.h2`
  color: #6cad9a;
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06563rem;
  margin: 0;
`;

const Subtitle = styled.h3`
  color: #26444d;
  font-size: 1.6875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06563rem;
  margin: 0;
`;

const Paragraph = styled.p`
  color: #26444d;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.03563rem;
  margin-bottom: 5%;
`;

const Lista = styled.li`
  height: 3rem;
  list-style-type: none;
  list-style-image: url(${(props) => props.icon});
  list-style-position: outside;
`;

const ListaContainer = styled.div`
  display: flex;
  align-items: center; /* Centra verticalmente los elementos */
  height: 2rem;
  margin-bottom: 10px; /* Espacio entre elementos de la lista */
  font-weight: 600;
`;
const ListaImagen = styled.img`
  /* Estilos para la imagen a la izquierda */
  width: 30px; /* Tamaño de la imagen */
  height: 30px;
  margin-right: 10px; /* Espacio entre la imagen y el texto de la lista */
`;
const ListaTexto = styled.span`
  /* Estilos para el texto de la lista */
  list-style-type: none;
  color: #26444d;
`;

const Tratamientos = () => {
  return (
    <CenteredContainer>
      <Section>
        <LeftColumn>
          <Title>CONTAMOS CON</Title>
          <Subtitle>Tratamientos especializados</Subtitle>
          <Paragraph>
            Cuida tu piel y figura con nuestros nuestros tratamientos corporales
            y faciales. Puedes agendar desde una sesión, hasta un paquete
            completo.
          </Paragraph>
          <ListaContainer>
            <ListaImagen src={Icon3} alt="Icono 3" />
            <ListaTexto>Procedimientos enfocados</ListaTexto>
          </ListaContainer>
          <ListaContainer>
            <ListaImagen src={Icon2} alt="Icono 2" />
            <ListaTexto>Aparatología de grado médico</ListaTexto>
          </ListaContainer>
          <ListaContainer>
            <ListaImagen src={Icon1} alt="Icono 1" />
            <ListaTexto>Tips y sugerencias</ListaTexto>
          </ListaContainer>
          <div style={{ marginTop: "60px" }}>
            <HeroButton href={"/tratamientos"}>Ver Todos</HeroButton>
          </div>
        </LeftColumn>
        <RightColumn>
          <Image src={TratamientosImage} alt="Imagen" />
        </RightColumn>
      </Section>
    </CenteredContainer>
  );
};

export default Tratamientos;
