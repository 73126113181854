import React from "react";
import styled from "styled-components";
import SideImage from "../../Resources/e13b438a12fe5a5e6bf569d8fee2e303.jpg";
import icon from "../../Resources/task_alt_FILL0_wght400_GRAD0_opsz24 4.svg";

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  margin-top: 5%;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    * {
      text-align: center;
    }
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: fit-content;
  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LeftColumn = styled.div`
  flex: 0 0 100%;
  max-width: 480px;
`;

const Image = styled.img`
  max-width: 450px;
  border-radius: 2.5rem 0rem 2.5rem 2.5rem;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.3);
  width: 60vw; /* Cambiamos el ancho de la imagen para que se ajuste al contenedor */
  height: auto; /* Hacemos que la altura sea automática para mantener la proporción */
  object-fit: cover;
  @media (max-width: 768px) {
    margin: 5% 0;
  }
`;

const RightColumn = styled.div`
  flex: 0 0 100%;
  max-width: 450px;
  padding: 0 0;
  width: 60vw;
  @media (max-width: 768px) {
    margin: 5% 0;
    width: 80%;
  }
`;

const Title = styled.h2`
  color: #6cad9a;
  font-style: normal;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06563rem;
  margin: 0;
`;

const Subtitle = styled.h3`
  color: #26444d;
  font-size: 1.6875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06563rem;
  margin: 0;
`;

const Paragraph = styled.p`
  color: #26444d;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.03563rem;
  margin-bottom: 5%;
`;

const ListaContainer = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  margin-bottom: 10px;
  font-weight: 600;
`;

const ListaImagen = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const ListaTexto = styled.span`
  list-style-type: none;
`;

const AboutNosotros = () => {
  return (
    <CenteredContainer>
      <Section>
        <LeftColumn>
          <Image src={SideImage} alt="Imagen" />
        </LeftColumn>
        <RightColumn>
          <Title>UNA FÓRMULA ÚNICA</Title>
          <Subtitle>Sistema del Dr. Arreola</Subtitle>
          <Paragraph>
            Hace 32 años el Dr. José Luis Arreola emprendió un viaje por la
            bariatría, buscando la fórmula entre el balance nutricional y apego
            a una dieta saludable sin causar el famoso “rebote”.
          </Paragraph>
          <Paragraph>
            Junto a su esposa, la Cosmetóloga Alma Delia, abren el Spa Médico
            enfocado al tratamiento del peso de manera integral. Después
            sumándose sus hijas, la Dra. Mariana Arreola Médico Bariatra y
            Daniela Arreola Nutrióloga y Esteto Cosmetóloga para terminar de
            armar un paquete nutricional, mental y físico para nuestros
            pacientes.
          </Paragraph>
          <ListaContainer>
            <ListaImagen src={icon} alt="Icono 3" />
            <ListaTexto>Procedimientos enfocados</ListaTexto>
          </ListaContainer>
          <ListaContainer>
            <ListaImagen src={icon} alt="Icono 2" />
            <ListaTexto>Aparatología de grado médico</ListaTexto>
          </ListaContainer>
          <ListaContainer>
            <ListaImagen src={icon} alt="Icono 1" />
            <ListaTexto>Tips y sugerencias</ListaTexto>
          </ListaContainer>
        </RightColumn>
      </Section>
    </CenteredContainer>
  );
};

export default AboutNosotros;
