import React from "react";
import styled from "styled-components";
import { HeroButton } from "../../components/bodycomponents";

const HeroContainer = styled.div`
  background-size: cover;
  background-position: center;
  height: 80vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  max-height: 800px;
  @media (max-width: 768px) {
    max-height: 500px;
    flex-direction: column;
  }
`;

const TextWrap = styled.div`
  margin-left: 10%;
  margin-bottom: 10%;
  position: relative; /* Add this to establish a stacking context */
  z-index: 1; /* Add this to place the text content above the background */
  @media (max-width: 768px) {
    margin-top: 100%;
    margin: 45vw 0 0 0;
    padding: 0 5%;
    background-color: #fff;
    height: 70vw;
    text-align: center;
  }
`;

const HeroTitle = styled.h1`
  width: 25%;
  color: #26444d;
  font-size: 2.5375vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.10313rem;
  margin-bottom: 10px;
  white-space: nowrap;
  @media (max-width: 768px) {
    font-size: 2.5375rem;
  }
`;

const HeroSubtitle = styled.p`
  width: 35%;
  margin-top: -15px;
  color: #26444d;

  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem; /* 136% */
  letter-spacing: 0.04688rem;
  justify-content: left;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
    font-size: 0.95rem;
    flex-wrap: nowrap;
  }
`;

const Hero = () => {
  return (
    <>
      <HeroContainer>
        <TextWrap>
          <HeroTitle>Piltic Medic Spa</HeroTitle>
          <HeroSubtitle>DECIDE SER TU MEJOR VERSIÓN DESDE HOY</HeroSubtitle>
          <HeroButton href="/nosotros">¡CONÓCENOS!</HeroButton>
        </TextWrap>
      </HeroContainer>
    </>
  );
};

export default Hero;
